import React, { FunctionComponent } from 'react'

const SectionFullScreen:FunctionComponent<{className?:String,removePadding?:boolean}> = ({className,removePadding,children})=> {
    
    return (
        <section className={`h-screen w-screen p-1 ${removePadding?'':'md:p-10'} ${className} animate-scroll`} data-animate-type="motion-safe:animate-fadeIn">
            <div className={`flex flex-col h-full ${removePadding?'':'md:px-10 pt-5'}`}>
                {children}
            </div>
        </section>
    )
}
export default SectionFullScreen;